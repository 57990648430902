<script lang="ts" setup></script>

<template>
  <div class="layout-wrapper">
    <AppHeader />
    <main>
      <slot />
    </main>
    <footer>
      <p class="mb-0 text-center" style="font-size: 14px; color: #9397ad;">
        كل الحقوق محفوظة لدى شركة ثقة المركبة للتجارة @ 2024
      </p>
    </footer>
  </div>
</template>

<style lang="css" scoped>
.layout-wrapper {
  background-image: url('/assets/images/404/background.png');
  background-size: cover;
  /* background-position: center center; */
  background-repeat: no-repeat;
  background-blend-mode: hard-light;
}

.layout-wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0;
  min-height: 100vh;
  height: 100%;
}

</style>
